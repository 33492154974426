@import "variables";

#footer {
  font-family: $body-font;
  padding-top: 57px;
  padding-bottom: 0px;
  background-repeat: no-repeat;
  background-position: -100px 85px;
  box-shadow: 0px 0px 5px $grey-lightest;

  h3 {
      margin-top: 0;
      margin-bottom: 30px;
      color: $grey;
  }

  h4 {
      margin: 20px 0;
      color: $grey;
  }
  
  li {
    margin-bottom: 30px;
  }

  .newsletter {
      .btn {
          max-width: 280px;
      }
  }
  
  .btn-secondary {
    width: 100%;
    color: $white;
  }
  .newsletter p {
      font-style: italic;
      line-height: 16px;
      margin-top: 10px;
      color: $grey-dark;
      a {
          text-decoration: underline;
          color: $grey-dark;
      }
  }
  li a {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $heading-font;
    letter-spacing: 3px;
    color: $grey;
  }

  a:hover, a:focus {
    color: $orange;
  }

  label {
    color: $grey-warm;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.4px;
    margin-bottom: 25px;
    margin-top: 15px;
  }

  input {
    margin-bottom: 25px;
  }
  .app-store-btn {
    width: 100px;
  }
  
  img {
    display: inline-block;  
  }
  
  .copyright {
    margin-top: 50px;
    text-align: right;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: $grey-dark;
    a {
        color: $grey-dark;
        text-decoration: underline;
        margin-right: 30px;
    }
  }
  
  .btn-primary {
    color: white;
    background-color: $orange;
    font-size: 16px;
    border-color: $orange;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    
    &:active, &:visited, &:hover {
      border-color: $orange;
    }
  }
  
  input[name="EMAIL"] {
    border: 1px solid #faa552;
    color: #faa552;
    text-transform: uppercase;
    font-weight: bold;
    padding: 19px;
    letter-spacing: 3px;
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
    
    &::placeholder {
      color: $orange;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
    padding-left: 100px;
  }
  
  .social-media li {
    display: inline;
  }
  
  @media (max-width: 768px) {
    img {
      max-width: 125px;
    }
    .col-md-3 {
      text-align: center;
    }
    .copyright {
      text-align: center;
    }
  }

}

#footer .app-store-btn {
  width: 140px;
}

#footer .btn-secondary {
  width: 100%;
  color: #ffffff;
}

#footer .btn-secondary:hover {
  width: 100%;
  color: #faa552;
}

#footer a:hover {
  color: #faa552;
}

