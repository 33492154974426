@import 'variables';

/** Main **/

html {
  position: relative;
}

body {
  padding-top: 0;
  font-family: $body-font;
  color: #999;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
}

h1 {
  font-size: $h1-size;
  letter-spacing: 5px;
  margin-top: 0;
}

h2 {
  font-size: $h2-size;
  letter-spacing: 5px;
}

h3 {
  font-size: $h3-size;
  letter-spacing: 3px;
}

h4 {
  font-size: $h4-size;
  letter-spacing: 3px;
}

p {
    font-family: $body-font;
    line-height: 24px;
    font-size: $paragraph-size;
    font-weight: 400;
}

hr {
    border-color: $grey-lighter;
}

.btn {
  border-radius: 0;
  background-color: transparent;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 14px;
  color: $white;
  padding: 19px 33px;
  text-transform: uppercase;
  transition: 0.15s ease-in;
  border-width: 2px;
  font-family: $heading-font;
  &.black:hover {
    color: $grey-dark;
  }
  &:hover {
    color: $grey-lightest;
  }
  
}

.btn-primary {
  border-color: $white;
  
  &:focus, &:hover, &:active, &:active:hover {
    background-color: $white-trans;
    border-color: $white;
    color: $white;
    background-color: $white;
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0.10),0 2px 10px 0 rgba(0,0,0,0.10);
    color: #faa552 ;
  }
  &.orange {
   border-color: $orange; 
   &:hover {
       color: $white !important;
       background-color: $orange;
   }
  }
}

.btn-secondary {
  background-color: $orange;
  color: $white;
  border-color: $orange;


  &:focus {
      color: $white;
  }
  &:hover {
    background-color: transparent       ;
    border-color: #faa552;
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0.10),0 2px 10px 0 rgba(0,0,0,0.10);
    color: #faa552;
  }
}

.btn-default {
  background-color: $white;
  color: $orange;
  border-color: $white;
  
  &:focus, &:hover, &:active, &:active:hover {
    background-color: transparent;
    border-color: $white;
    box-shadow: 0 0px 0px 0     rgba(0,0,0,0.10),0 2px 10px 0 rgba(0,0,0,0.10);
    color: $grey-dark;
  }
}

.alert {
    margin-bottom: 0;
    border-radius: 0;
    p {
        margin-top: 5px;
    }
}
.alert+.navbar-default {
    top: 50px;
    position: absolute;
}

.alert+.navbar-default-dark {
    top: 0px;
    position: fixed;
}

.alert-orange {
    background-color: $orange;
    color: $white;
    .close {
        color: $white;
    }
    a {
        color: $white;
        font-weight: bold;
    }
}

.cookie-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.error {
    color: red;
}

.well {
  border: 0;
  border-radius: none;
}

.spinner {
    background: url('/wp/wp-admin/images/spinner.gif') no-repeat;
    background-size: 16px 16px;
    opacity: .7;
    width: 16px;
    height: 16px;
    margin: 5px 5px 0;
}

section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home section p {
    margin: 30px 0;
}

.about-page .section {
    padding: 60px;
}

.content {
    padding: 40px 0;
}

header {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0 0;
  height: 500px;
  position: relative;
  
  .back-btn {
    position: absolute;
    bottom: 30px;
  }
}

.emblem-bg {
  background-repeat: no-repeat;
  background-position: right -15%;
  
} 

#welcome {
  height: -webkit-calc(100vh);
  height: -moz-calc(100vh);
  height: calc(100vh);
  min-height: 675px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  h1 {
    color: $white;
  }
  h4 {
    color: $white;
    margin-bottom: 24px;
  }
  
  .welcome-emblem {
    max-width: 250px;
    margin-bottom: 20px;
  }
  
}

#retreats {
  
  h2 {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 5px;
    margin-bottom: 30px;
    border: 2px #faa552 solid;
    display: inline-block;
    padding: 9px 14px;
  }
  h3 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  p {
    margin: 30px 0;
    margin-bottom: 60px;
  }
  .btn {
    width: 200px;
    margin: 20px;
  }
}

.daterange {
    display: flex;
    margin: 28px 0;
    input {
        flex: 1;
    }
    .rangeto {
        padding: 12px 28px;
    }
}

.preview-box {
  border: solid 1px $orange;
  padding: 0px 25px 0px 0;
  margin: 20px 0;
  box-shadow: 5px 5px 5px $grey-lightest;
  &.past {
    opacity: 0.5;
  }
  .row {
    margin: 0;
  }
  
  .thumbnail {
    border: 0;
    background-color: transparent;
    padding: 0;
    height: 375px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    border-radius: 0;
    background-position: center;
  }
  
  .col-md-8 {
    padding-left: 50px;
    padding-top: 20px;
  }

  
}

#posts {
  padding: 0;
  padding-top: 50px;
  padding-bottom: 100px;
  
  .post-thumbnail {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px 5px 0 0;
    box-shadow: 1px 3px 5px 0px $grey-warm;
  }
  
  .white-box {
    border-radius: 0 0 5px 5px;
    text-align: left;
    min-height: 395px;
    
    h4 {
      letter-spacing: 5px;
    }
    
    a {
      font-weight: normal;
      border: 0;
      letter-spacing: 0;
      background-color: transparent;
      font-size: 16px;
    }
  }
}

#testimonials {
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
    font-style: italic;
    font-weight: normal;
    margin: 0;
  }

  p {
      font-size: 16px;
  }
}

#contact {
  
  .bg-orange-trans {
    box-shadow: inset 0 0 0 1000px rgba(255, 185, 95, 0.8);
    background-position: -118px -65px;
    background-size: 100%;
    background-color: rgb(255, 185, 95);
  }
  
  .btn {
    width: 249px;
    border-color: $white;
    background-color: transparent;
    &:hover {
        color: $grey-dark;
    }
  }

  p {
      margin: 30px 0;
  }

  form p {
      display: none;
  }
  
  input[name="EMAIL"] {
    border: 0;
    color: $orange;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    letter-spacing: 3px;
    font-size: 16px;
    position: relative;
    width: 249px;
    left: 1px;
    
    &::placeholder {
      color: $orange;
    }
  }
  
  .col-sm-6 {
    height: 500px;
    
    &.bg-image {
      background-size: cover;
      background-position: center;
      height: 500px;
    }
  }
  p {
      margin: 30px 0;
  }
}

.section {
  margin: 0;
  padding: 74px;
  padding-bottom: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 450px;

  h1 {
    margin-bottom: 25px;
  }
  
  .col-md-4 {
    margin-bottom: 30px;
  }
  
  @media(max-width: 576px) {
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 100px;
  }
}

.subtitle {
  letter-spacing: 7px;
  padding-bottom: 150px;
}

.gallery_image {
  height: 300px;
  display: block;
  background-size: cover;
  margin-bottom: 30px;
  background-position: center;
}

.col-container {
  display: table; /* Make the container element behave like a table */
  width: 100%; /* Set full-width to expand the whole page */
}

.col-50 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 50%;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
}

.col-md-6 {
  @media (max-width: 576px) {
    height: auto;
  }
}

.section.single {
  padding: 100px 0 120px 0;
}

.section.main {
  @media (max-width: 576px) {
    padding-top: 100px;
  }
}

.section.no-bg {
  background-image: none !important;
}

.section.box-grid {
  margin-bottom: -25px;
}

.bottom-border {
  border-bottom: 2px solid $grey-lightest;
}

.press-index {
    .row {
        display: flex;
        flex-wrap: wrap;
        .press-box {
            display: flex;
            flex-direction: column;
            .white-box {
                flex: 1;
            }
        }
    }
}

@media (max-width: 1200px) {
  .section.box-grid {
    margin-bottom: -125px;
  } 
}


/** Boxes **/
.white-box {
  padding: 25px 50px 45px 50px;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 1px 3px 5px 0px $grey-warm;
}

.blank-box {
  padding: 25px 50px 45px 50px;
}

.large-box {
  padding: 65px;
  margin: -50px -15px -50px -15px;
  small {
    color: $white;
  }
}

@media (max-width: 1200px) {
  .large-box {
    margin: -50px -15px 50px -15px;
  }
}

.resource-box {
  padding: 25px 50px 25px 50px;
  margin-bottom: 25px;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0px 1px 3px 1px $grey-warm;
  cursor: pointer;

  .content {
    height: 275px;
  }

  strong {
    font-weight: 400;
  }

}

.resource-box:hover {
  background-color: $navy;
  p, h3 {
    color: $white;
  }
}

/** Background Colors **/
.bg-navy-trans {
  box-shadow: inset 0 0 0 1000px $navy-trans;
}

.bg-orange-trans {
  box-shadow: inset 0 0 0 1000px $orange-trans;
  color: $white;
  background-color: $black;
  p {
      font-weight: 600;
  }
  a {
      color: $white;
  }
  .btn-default {
      color: $orange;
      &:hover {
          color: $white;
      }
  }
}

.bg-green-trans {
  box-shadow: inset 0 0 0 1000px $green-trans;
}

.bg-blue-trans {
  box-shadow: inset 0 0 0 1000px $blue-trans;
}
.bg-black-light-trans {
  box-shadow: inset 0 0 0 1000px $black-light-trans;
  color: $white;
}
.bg-grey-trans {
  box-shadow: inset 0 0 0 1000px $grey-trans;
  
  h1, h2, h3, h4, h5, p {
    color: $grey;
  }
}
.bg-black-trans {
  box-shadow: inset 0 0 0 1000px $black-trans;
  
  h1, h2, h3, h4, h5, p {
    color: $white;
  }
}
.bg-orange {
  background-color: $orange;
  color: $white;
}


/** Icons **/
.pic-icon {
  img {
    border-radius: 250px;
    max-width: 125px;
    border: 2px solid $orange;
  }
}

.pic-icon-green {
  img {
    border-radius: 250px;
    max-width: 125px;
    border: 2px solid $green;
  }
}

.flow-icon {
  margin-top: 50px;
}

.img-resource {
  float: left;
  height: 55px;
  margin-right: 10px;
}

.custom-icon {
  height: 40px;
  margin-bottom: 5px;
}

/** Hide **/
.invisible {
  visibility: hidden;
}

/** Margins **/
.margin-0 {
  margin: 0;
}
.margin-top-25  {
  margin-top: 25px; 
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-50-percent {
  margin-top: 20%;
}

.margin-top-150 {
  margin-top: 150px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-150 {
  margin-bottom: 150px;
}

.margin-left-50 {
  margin-left: 50px;
}

@media (max-width: 768px) {
  .margin-top-25  {
    margin-top: 0; 
  }

  .margin-top-50 {
    margin-top: 0;
  }

  .margin-top-50-percent {
    margin-top: 50%;
  }
  
  .margin-top-150 {
    margin-top: 0;
  }

  .margin-bottom-150 {
    margin-bottom: 150px;
  }

  .margin-left-50 {
    margin-left: 50px;
  }
}

/** Elements **/

.img-icon {
  height: 50px;
}

p.large {
  font-size: $h5-size;
  font-weight: 400;
  color: $grey-warm;
}

input.trans-input {
  background-color: $white-trans !important;
  color: $white;
  font-weight: 300;
  border-radius: 5px;
  outline: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.trans-input::-webkit-input-placeholder { color: $white; }  /* WebKit, Blink, Edge */
.trans-input:-moz-placeholder { color: $white; }  /* Mozilla Firefox 4 to 18 */
.trans-input::-moz-placeholder { color: $white; }  /* Mozilla Firefox 19+ */
.trans-input:-ms-input-placeholder { color: $white; }  /* Internet Explorer 10-11 */
.trans-input::-ms-input-placeholder { color: $white; }  /* Microsoft Edge */

.input-group-addon {
  color: $white;
  padding: 17px 12px;
  background-color: $white-trans;
  border: transparent;
}

.uppercase {
  text-transform: uppercase;
}

h1.underline {
  font-size: $lg-size;
  font-weight: 600;
  border-bottom: 5px solid $orange;
  display: inline-block;
  margin-bottom: 75px;
}

a {
  color: $orange;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: $orange-dark-gradient;
  text-decoration: none;
}

a.white {
  color: $white;
}

a.white:hover, a.white:active, a.white:focus {
  color: $grey-light;
}


/** Colors **/
.orange {
  color: $orange !important;
}

.white {
  color: $white;
}

.black {
  color: $grey-dark;
}

.gray {
  color: $grey;
}

.blue {
  color: $blue-light;
}

.green {
  color: $green;
}

.navy {
  color: $navy;
}

/** 5 Column Grid **/
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

/** Slider **/
.carousel {
  padding-bottom: 100px;
  
  p {
    padding: 0 200px;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 44px;
    @media(max-width: 576px) {
      padding: 0 20px;
    }
  }
  
  .profile {
    width: 70px;
    height: 70px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    box-shadow: 0 0 5px $black-trans;
    display: inline-block;
  }
  
  .carousel-indicators {
    li {
        background-color: transparentize($white, 0.6);
        height: 12px;
        width: 12px;
        border-radius: 12px;
        &.active {
          background-color: $orange;
          border: 0;
        }
        border: 0;
    }
  }
  
  .carousel-control {
    top: 60px;
    .circle-bg {
      @media(max-width: 576px) {
        display: none;
      }
    }
    &.left {
      .circle-bg {
        left: 50px;
      }
    }
    &.right {
      .circle-bg {
        right: 50px;
      }
    }
    &.left, &.right {
      background: none;
      opacity: 1;
      .circle-bg {
        top: 0;
        position: absolute;
        border-radius: 103px;
        border-color: transparentize($white, 0.5);
        border-width: 2px;
        border-style: solid;
        width: 60px;
        height: 60px;
        .fa-angle-left, .fa-angle-right {
          top: 12px;
          left: 0px;
          color: $white;
          text-shadow: none;
          font-size: 32px;
          position: relative
        }
        .fa-angle-left {
            left: -2px;
        }
        .fa-angle-right {
            left: 2px;
        }
        &:hover {
            background-color: $white;
            border-color: $white;
            .fa-angle-left, .fa-angle-right {
                color: $orange;
            }
        }
      }
    }
  }
}


/** Input **/
input:focus, textarea:focus {
    outline: none;
}

.form-control:focus {
  border-color: $orange;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
}

/** Effects **/
.dimmed {
  position: relative;
  padding: 1px;
}

.dimmed:before {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: $black-trans;
}

/** Modal Close **/
.close {
  opacity: 1;
}

/* style overrides for https://phabricator.vaultinnovation.com/T2769 */

#footer .app-store-btn {
  width: 140px;
}

#footer .btn-secondary {
  width: 100%;
  color: #ffffff;
}

#footer .btn-secondary:hover {
  width: 100%;
  color: #faa552;
}


#footer a:hover {
  color: #faa552;
}

#welcome .welcome-emblem {
  max-width: 200px;
}

/* end style changes for Phabricator */

/* emblem size on the about page */
.emblem {
  width: 150px;
}


/* this is for the selector */
select.form-control {
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;       /* remove default arrow */
  background-image: url("/app/themes/yoga/assets/img/ic-arrow-drop-down-circle@3x.png");   /* add custom arrow */
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
  height: 50px;
  padding: 8px 16px;
}

table.fullcalendar {
  width: 100%;
  margin: 50px auto;
}

table.fullcalendar td {
  width: 14%;
  height: 100px;
  vertical-align: top;
}
.grecaptcha-badge { bottom: 66px !important; }
