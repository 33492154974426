@import "variables";

@media (max-width: $mobile-breakpoint) {
  h1 {
      font-size: 42px;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  #mobile-logo {
    display: block !important;
  }

  #nav-logo {
    display: none !important;
  }

  .hamburger {
    display: block !important;
  }

  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }

  #footer {
      background-size: 175px;
      .newsletter {
          margin-top: 30px;
          margin-bottom: 30px;
      }
      ul {
          padding: 0;
          li {
              text-align: center;
          }
      }
      .copyright {
          text-align: center;
          a {
              margin: 0;
              display: block;
          }
      }
  }
}
