@import "variables";

#svgContainer { 
  z-index: 0;
  opacity:  0.9;
  margin:   2.5em 2.5em;
  position: absolute;  
}

path { 
  fill: none;
  stroke: $white-trans-line;
  stroke-width: 10px;
}

