@import 'variables';

.home-page-blog-posts {
    @media (min-width: 767px) {
        display: flex;
        align-items: stretch;
        min-height: 100%;
        .home-page-blog-post {
            float: none;
            width: auto;
            display: flex;
            flex-direction: column;
            .white-box {
                flex-grow: 1;
            }
        }
    }
}

.home section .row {
    @media (min-width: 767px) {
        display: flex;
    }
    margin: 0;
    p a {
        color: $grey-lightest;
    }
}
