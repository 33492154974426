// Mobile
$mobile-breakpoint: 991px;

// Colors
$blue: #629dbb;
$blue-light: #6aa2be; 
$blue-trans: rgba(98, 157, 187, 0.95);
$blue-trans-light: rgba(98, 157, 187, 0.85);
$navy: #252f5d;
$navy-light: #252f5d; 
$navy-trans: rgba(37, 47, 93, 0.95);
$white: #ffffff;
$white-dark: #f6f6f6;
$white-off: #f1f1f1;
$white-trans: rgba(255, 255, 255, 0.2);
$white-trans-line: rgba(255, 255, 255, 0.5);
$grey-dark: #4a4a4a;
$grey-warm: #6c6c6c;
$grey-light: #9b9b9b;
$grey-lighter: #d4d4d4;
$grey-lightest: #ececec;
$grey-trans: rgba(255, 255, 255, 0.8);
$grey: #4a4a4a;
$green: #b6d35a;
$green-light: #b6d35a;
$green-trans: rgba(182, 211, 90, 0.95);
$orange: #ECAB58;
$orange-light: lighten($orange, 5%);
$orange-trans: lighten(saturate(transparentize($orange, 0.05), 60%), 5%);
$orange-trans-light: lighten($orange-trans, 5%);
$black: #231f20;
$black-trans: rgba(50, 50, 50, 0.8);
$black-light-trans: rgba(50, 50, 50, 0.15);

// Text
$text-color: $grey;

// Gradients
$orange-light-gradient: #f98303;
$orange-dark-gradient: #eb3f00;

// Fonts
$body-font: "Open Sans", Arial, Helvetica, sans-serif;
$heading-font: "Cabin", "Open Sans", "Source Sans Pro", sans-serif;

// Sizes
$base-font-size: 16px;
$paragraph-size: $base-font-size;
$label-size: 16px;
$xl-size: 90px;
$lg-size: 60px;
$h1-size: 55px;
$h2-size: 36px;
$h3-size: 24px;
$h4-size: 24px;
$h5-size: 20px;
