@import "variables";

.btn {
  margin-top: 10px;
}

.btn-select {
  .btn {
    margin-left: 35px;
    margin-right: 35px;
    opacity: .5;
  }

  .btn-fixed {
    margin-left: 15px;
    margin-right: 15px;
  }

  .btn:active, .btn.active {
    opacity: 1;
    outline: none;
    border: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.btn[disabled] {
    color: $white;
    background-color: $grey;
    border-color: $grey;
    &:hover {
        color: $white;
        background-color: $grey;
        border-color: $grey;
    }
}

.btn-fixed {
  width: 205px;
}

.btn-fixed.small {
  width: 175px;
}

@media (max-width:768px) {
  .btn-fixed { 
    width: 93%; 
    display:block; 
  }
  .btn-fixed.small {
    width: auto;
  }
} 

/** Clear Button **/
.btn-clear {
  border-radius: 2px !important;
  padding: 5px 30px 0px 30px;
  font-size: $h5-size;
  text-transform: uppercase;
  color: $white;
  background-color: transparent; 
  border-color: $white;
}

.btn-clear:hover, .btn-clear:active, .btn-clear:focus {
  color: $grey-light;
  background-image: none;
  background-color: transparent;
  border-color: $grey-light;
  outline: none;
}

/** White Button **/
.btn-white {
  color: $orange !important;
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  background-color: $white; 
  border: 2px solid $white;
}

.btn-white:hover, .btn-white:active, .btn-white:focus {
  color: $orange-light !important;
  background-image: none;
  background-color: $white;
  border-color: $white-off;
  outline: none;
}

.btn-white-navy {
  color: $navy !important;
  border-radius: 5px !important;
  padding: 10px 5px 10px 5px;
  font-size: $h5-size;
  background-color: $white; 
  border: 2px solid $white;
}

.btn-white-navy:hover, .btn-white-navy:active, .btn-white-navy:focus {
  color: $navy-light !important;
  background-image: none;
  background-color: $white;
  border-color: $white-off;
  outline: none;
}

.btn-white-trans {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $white;
  background-color: transparent; 
  border: 2px solid $white;
}

.btn-white-trans:hover, .btn-white-trans:active, .btn-white-trans:focus {
  color: $white-off;
  background-image: none;
  background-color: transparent;
  border-color: $white-off;
  outline: none;
}


/** Blue Button **/
.btn-blue {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $white;
  background-color: $blue; 
  border: 2px solid $blue;
}

.btn-blue:hover, .btn-blue:active, .btn-blue:focus {
  color: $white-off;
  background-image: none;
  background-color: $blue-light;
  border-color: $blue-light;
  outline: none;
}

/** Orange Button **/
.btn-orange, .btn-orange:focus, .btn-orange:active {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $white;
  background-color: $orange; 
  border: 2px solid $orange;
}

.btn-orange:hover {
  color: $white-off;
  background-image: none;
  background-color: $orange-light;
  border-color: $orange-light;
  outline: none;
}

/** Orange Trans **/
.btn-orange-trans {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $orange;
  background-color: transparent; 
  border: 2px solid $orange;
}

.btn-orange-trans:hover, .btn-orange-trans:active, .btn-orange-trans:focus {
  color: $orange-light;
  background-image: none;
  background-color: transparent;
  border-color: $orange-light;
  outline: none;
}

/** Navy Button **/
.btn-navy {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $white;
  background-color: $navy; 
  border: 2px solid $navy;
}

.btn-navy:hover, .btn-navy:active, .btn-navy:focus {
  background-image: none;
  background-color: $navy-light;
  border-color: $navy-light;
  outline: none;
}

/** Navy Trans **/
.btn-navy-trans {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $navy;
  background-color: transparent; 
  border: 2px solid $navy;
}

.btn-navy-trans:hover, .btn-navy-trans:active, .btn-navy-trans:focus {
  color: $navy-light;
  background-image: none;
  background-color: transparent;
  border-color: $navy-light;
  outline: none;
}

/** Green Button **/
.btn-green {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $white;
  background-color: $green; 
  border: 2px solid $green;
}

.btn-green:hover, .btn-green:active, .btn-green:focus {
  color: $white-off;
  background-image: none;
  background-color: $green-light;
  border-color: $green-light;
  outline: none;
}

/** Green Trans **/
.btn-green-trans {
  border-radius: 5px !important;
  padding: 10px 50px 10px 50px;
  font-size: $h5-size;
  color: $green;
  background-color: transparent; 
  border: 2px solid $green;
}

.btn-green-trans:hover, .btn-green-trans:active, .btn-green-trans:focus {
  color: $green-light;
  background-image: none;
  background-color: transparent;
  border-color: $green-light;
  outline: none;
}
