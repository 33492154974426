@import "variables";

.daterange .form-control.datepicker {
    font-size: 14px;
}
.daterange .btn {
    padding: 8px 33px;
    margin-left: 10px;
    margin-top: 0px;
}

.bootstrap-datetimepicker-widget {
    width: 21em;
    ul.list-unstyled {
        margin: 10px;
    }
    .table-condensed {
        tbody {
            padding: 10px;
        }
        th.dow {
            text-transform: uppercase;
            color: $grey-dark;
            font-size: 13px;
        }
        .prev, .next, .picker-switch {
            color: $orange;
        }
        td.day {
            font-size: 13px;
            color: $grey-light;
            &.old {
                color: $grey-lighter;
            }
        }
        tbody tr td {
            padding: 0 5px;
            &.active, &.active:hover {
                background-color: $orange;
                color: $white;
            }
            &.today:before {
                display: none;
            }
        }
    }
}
