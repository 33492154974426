@import "variables";

header .container {
    height: 100%;
    position: relative;
    .back-btn {
        position: absolute;
        bottom: 0px;
        left: 0;
        font-style: italic;
        font-size: 12px;
    }
}

h3.subtitle {
    font-style: italic;
    margin-top: 20px;
}

ul.post-categories {
    list-style: none;
    float: right;
    text-transform: uppercase;
}

.wp-caption {
   border: 1px solid $grey-lighter;
   padding: 10px;
   &.aligncenter {
       margin: 10px auto;
   }
   img {
       max-width: 100%;
   }
   .wp-caption-text {
       margin: 0;
   }
}
