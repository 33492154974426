@import "variables";

.campaign {
  background-image: url('/app/themes/yoga/assets/img/pic-icon.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 125px 10px 125px 10px;
  box-shadow: inset 0 0 0 1000px $black-trans;
  height: 425px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 25px;
  
  h2 {
    margin-top: 0;
    font-size: $h2-size !important;
  }

  h4 {
    margin-top: 45%;
  }
}

.campaign:hover {
  box-shadow: inset 0 0 0 1000px $blue-trans-light;
  opacity: 1 !important;
}
