@import "variables";

// Base
#navbar {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: $heading-font;
}

@media (max-width: 768px) {
    .navbar-nav#social-media { > li {
            float: left;
        }
    }
}


.navbar-brand {
  height: 25px; 
  padding: 0;
}

.navbar-default-dark {
  background-color: $white !important;

  .navbar-nav>li>a {
    color: $grey !important;
  }
}

.navbar-default {
  height: auto;
  padding: 10px 30px;
  background-color: transparent;
  border-color: transparent;
  
  #social-media {
    img {
      max-width: 16px;
    }
    #appstore {
      padding: 3px 0 0;
      img {
        max-width: 200px;
      }
    }
    a {
      font-size: 24px;
    }
  }

  // Dropdown on hover
  ul.nav li.dropdown:hover ul.dropdown-menu {
      display: block;
  }
}

// Nav Items
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
    color: $white;
    background-color: transparent;
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0px;
    border-radius: 0;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0;
    border: none;
}

.dropdown-menu>li>a {
    display: block;
    padding: 20px 25px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: $grey-warm;
    white-space: nowrap;
}

.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
    color: $white;
    text-decoration: none;
    background-color: $orange;
}

.navbar-default {
  -moz-transition: background-color .3s ease-in-out;
  -o-transition: background-color .3s ease-in-out;
  -webkit-transition: background-color .3s ease-in-out;
  transition: background-color .3s ease-in-out;
}

.navbar-default-dark>li>a:hover {
  color: #faa552;
}
.navbar-default #social-media #appstore img {
  max-width: 140px;
  padding-top: 5px;
}

.navbar-default-dark .navbar-nav>li>a:hover {
  color: #faa552;
  background-color: transparent;
}
.navbar .navbar-collapse {
  text-align: center;
}

.navbar-default .navbar-nav {
    margin-left: 30px;
}

.navbar-default .navbar-nav>li>a {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 20px;
  letter-spacing: 3px;
  color: $white;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
  color: $white-off;
  background-color: transparent;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
  color: $white-off;
  background-color: transparent;
}

.nav-pills {
    background-color: $grey-lightest;
    border-radius: 40px;
    font-size: 16px;
    align-items: center;

    @media (min-width: 768px) {
        display: flex;
        li {
            flex-basis: 0;
            flex-grow: 1;
        }
    }
    @media (max-width: 768px) {
        li {
            float: none;
        }
    }
    li > a {
        color: $grey-warm;
        padding: 16px 20px;
        text-align: center;
        border-radius: 40px;
    }
    li.active > a {
        &,&:focus,&:hover {
            background-color: $orange-light;
            border-radius: 40px;
        }
    }
    margin-bottom: 30px;
}

// Nav logo
.logo {
  height: 55px;
  margin-top: 15px;
}

#mobile-logo {
  display: none;
}

// Mobile Nav
@media(max-width: $mobile-breakpoint) {
  .logo {
    height: 55px;
    margin-left: 15px; 
    margin-top: 8px;
  }
  #navbar {
    margin: 5px -30px 0px -30px;
  }
  .navbar-collapse {
    border: none;
    box-shadow: none;
  }
  .navbar-default {
    background-color: $white !important;
    margin: 0;
  }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent !important;
  }
  .navbar-default .navbar-toggle {
    margin-top: 10px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: $grey-dark;
  }
  .navbar-default .navbar-nav>li>a {
    color: $grey-warm;
    margin: 25px;
    padding: 0;
  }
  .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
    color: $grey-warm !important;
  }

  .navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
    color: $grey-warm !important;
    background-color: transparent;
  }

}
