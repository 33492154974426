@import 'variables';

.fc {
    margin: 80px 0 40px;
}

.fc-event {
    background-color: $orange;
    border-color: $orange;
    color: $white;
    &.past {
        opacity: 0.8;
    }
}

.event-header {
    .fa-usd {
        width: 37px;
        border: 2px solid $white;
        border-radius: 37px;
    }
}

.event-section, .ticket-section {
    h1 {
        color: $orange;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 10px;
    }
    h2 {
        color: $orange;
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    h3 {
        color: $grey-dark;
        font-weight: 800;
        letter-spacing: 0;
        text-transform: none;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .faq {
        margin-bottom: 60px;
        p {
            color: $grey-dark;
            font-size: 18px;
            font-weight: 400;
        }
    }
}

.retreat-details {
    ul {
        font-size: 16px;
        line-height: 24px;
        li {
            padding-left: 16px;
        }
    }
    .widget_sow-slider ul li {
        padding-left: 0;
    }
    .bg-orange-trans {
        padding-top: 5px;
    }
    .retreat-header {
        padding-bottom: 20px;
        .text-left {
            text-align: center;
        }
    }
    h3:first-child {
        margin-top: 0;
    }
}

.event-description, .registration {
    h3, h4, h5, h6 {
      color: #4a4a4a;
    }

    h3, h4, h5, h6 {
      text-transform: none;
    }
}

.registration {
    h2 {
        color: #4a4a4a;
    }
    .booking-info {
        i {
            display: inline-block;
            text-align: center;
            width: 24px;
            color: #bbb;
        }
        strong {
            font-size: 15px;
            color: #4a4a4a;
        }
        p {
            margin-top: 16px;
        }
    }
    a[data-toggle="nexttab"] {
        margin-top: 36px;
    }
}

.event-section + #footer {
    margin-top: 100px;
}

.ticket-section {
    background-color: #f6f6f6;
    padding: 80px;
    margin: 40px;
}

.ticket-row {
    margin-top: 30px;
    margin-bottom: 30px;
    h3 {
        color: $black;
        text-transform: capitalize;
        font-weight: bold;
        padding-top: 0;
        margin-top: 0;
    }
    .ticket-price {
        margin-top: 30px;
        display: block;
        color: $grey;
        font-size: 26px;
        font-weight: 600;
    }
    .ticket-price-unavailable {
        text-decoration: line-through;
    }
    &.past {
        opacity: 0.5;
    }
}

.info-checkbox-legend {
    text-align: right;
    margin: 28px 0 12px;
    .info-checkbox-legend-item {
        display: inline-block;
        width: 29px;
        font-size: 17px;
        margin-right: 23px;
    }
}

.info-checkbox-content {
    background-color: lighten($white-off, 2%);
    padding: 28px 28px 16px;
    .info-checkbox-content-row {
        margin-bottom: 16px;
    }

    label {
        font-size: 16px;
        color: #b6b6b6;
        display: inline;
    }
    .radio-wrapper {
        float: right;
    }
    input {
        display: inline;
        margin-right: 0px;
        margin-left: 40px;
        width: auto;
        height: auto;
    }
}

.info-question-label {
    margin: 28px 0 12px;
}

.form-control {
    font-size: 20px;
    padding: 24px 16px;
}

.waiver-box {
    height: 500px;
    font-size: 14px;
    overflow-y: scroll;
    border: 1px solid $grey-dark;
    padding: 24px;
}

.datefilter {
    margin-top: 20px;
}

.em-my-bookings {
    margin-top: 50px;
    min-height: 200px;
}
.sow-slider-base {
    display: block !important;
}
